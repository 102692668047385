import cx from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import Contact from '../../Contact';
import Container from '../../Container/index';
import Header from '../../Header';
import LightBulb from '../../LightBulb/index';

import tableBlackImage from './table-black.svg';
import tableColorImage from './table-color.svg';

import styles from './index.module.scss';

export default class extends React.Component {
  state = {
    lightsOn: false,
    flickering: false
  };

  componentDidMount() {
    setTimeout(() => this.setState({ flickering: true }), 1000);
    setTimeout(() => this.setState({ lightsOn: true, flickering: false }), 2000);
  }

  render() {
    const { className } = this.props;
    const { lightsOn, flickering } = this.state;

    return (
      <div
        className={cx(className, {
          [styles.root]: true,
          [styles.lightsOn]: lightsOn
        })}
      >
        {lightsOn && <Header animate />}
        <LightBulb className={styles.lightBulb} lightsOn={lightsOn} flickering={flickering} />
        <Container className={styles.container}>
          <div className={styles.text}>
            <h3 className={styles.heading}>We do tech stuff</h3>
            <div className={styles.services}>
              <Link to="/apps">
                <span>Apps</span>
              </Link>
              <Link to="/websites">
                <span>Websites</span>
              </Link>
              <Link to="/branding">
                <span>Branding</span>
              </Link>
            </div>
            <p>
              Oops Typo brings ideas to life. Our technical and creative expertise allows us to become trend setters on
              the web and beyond.
            </p>
            <Contact className={styles.contact} />
          </div>
          <img
            className={`${styles.table} ${lightsOn ? styles.lightsOn : ``}`}
            src={lightsOn ? tableColorImage : tableBlackImage}
            alt="Table"
          />
        </Container>
      </div>
    );
  }
}
