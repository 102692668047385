import cx from 'classnames';
import React from 'react';
import styles from './index.module.scss';

/* eslint-disable react/prop-types */
export default ({ lightsOn, flickering, className }) => (
  <div className={`${styles.root} ${className || ''}`}>
    <div
      className={cx({
        [styles.bulb]: true,
        [styles.on]: lightsOn,
        [styles.flickering]: flickering
      })}
    />
    <div className={`${styles.wire} ${!lightsOn ? styles.hasShadow : ``}`} />
  </div>
);
