import React from 'react';
import Layout from '../components/Layout';
import HomePage from '../components/pages/home';

export default class extends React.Component {
  render() {
    return (
      <Layout>
        <HomePage />
      </Layout>
    );
  }
}
